
<template>
  <validation-observer ref="assistantForm">
    <b-toast
      :visible="isFailed"
      title="Warning"
      variant="warning"
    >
      {{ $t('messages.invalid_form') }}
    </b-toast>
    <form @submit.prevent="onSubmit">
      <b-form-group
        v-if="assistantId"
        label-cols-sm="12"
        label-cols-lg="3"
        label="สร้างโดย"
        label-for="creator"
      >
        <b-form-input
          id="creator"
          v-model="assistantDetail.createdBy"
          readonly
        />
      </b-form-group>
      <validation-provider
        v-slot="{ errors }"
        :name="$t('user.username')"
        :rules="assistantId?null:'required'"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`ยูสเซอร์เนม  ${assistantId?'':'*'}`"
          label-for="username"
        >
          <b-form-input
            id="username"
            v-model="assistantData.username"
            placeholder="@username"
            :state="errors[0] ? false : null"
            :readonly="!!assistantId"
          />
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-if="!assistantData.id"
        v-slot="{ errors }"
        :name="$t('user.password')"
        :rules="assistantData.id?null:'required'"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('user.password')} *`"
          label-for="agent_password"
        >
          <b-input-group>
            <b-form-input
              id="agent_password"
              v-model="assistantData.password"
              :type="isShowPassword?'text':'password'"
              :state="errors[0] ? false : null"
              autocomplete="off"
            />
            <template #append>
              <b-button @click="isShowPassword = !isShowPassword">
                <feather-icon
                  :icon="isShowPassword?'EyeIcon':'EyeOffIcon'"
                />
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </validation-provider>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label="ชื่อ"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model="assistantData.name"
        />
      </b-form-group>
      <validation-provider
        v-slot="{ errors }"
        name="phoneNumber"
        rules="min:10|regex:^([0-9]+)$"
      >
        <b-form-group
          :label="$t('user.phone')"
          label-for="phoneNumber"
          label-cols-sm="12"
          label-cols-lg="3"
        >
          <b-form-input
            id="phoneNumber"
            v-model="assistantData.phoneNumber"
            v-mask="'##########'"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            aria-describedby="input-live-feedback"
          />
          <b-form-invalid-feedback id="input-live-feedback">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        label="เลือก Role"
        label-for="role"
      >
        <b-select
          id="role"
          v-model="selectedRole"
          :disabled="isFetchingRoles"
        >
          <b-form-select-option value="">
            กำหนดเอง
          </b-form-select-option>

          <b-form-select-option
            v-for="(role, roleI) in roleOptions"
            :key="roleI"
            :value="role.value"
          >
            {{ role.name }}
          </b-form-select-option>
        </b-select>
      </b-form-group>
      <h5 class="mb-2">
        {{ $t('roles.permissions') }}
      </h5>
      <b-row>
        <b-col
          v-for="(permission, index) in permissionsData"
          :key="index"
          cols="4"
          class="mb-1"
        >
          <b-form-checkbox
            :id="`permission-${permission.key}`"
            v-model="permission.isSelected"
            switch
          >
            {{ permission.name }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <div class="text-right">
        <b-button
          class="mr-2"
          :to="{ name: 'VIEW_ASSISTANTS'}"
          variant="light"
        >
          {{ $t('buttons.back') }}
        </b-button>
        <b-overlay
          :show="isLoading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { required } from '@validations'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      required,
      isFailed:false,

      isShowPassword: false,
      selectedRole: '',
      assistantData: {
        id: null,
        phoneNumber: '',
        username: '',
        password: '',
        name: '',
        permissionIds: [],
      },
      permissionsData: [],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.assistant.isCreatingAssistant || state.assistant.isUpdatingAssistant,
      isFetchingRoles: (state) => state.role.isFetchingRoles,
      
    }),
    ...mapGetters(['assistantDetail', 'roles', 'permissions']),
    assistantId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
    assistantPermissions() {
      return this.assistantDetail.permissions
    },
    roleOptions() {
      return this.roles.map((role) => {
       return { value: role.id, name: role.name}
      }) || []
    },
    permissionIds() {
      return this.permissionsData.reduce((acc, val) => {
        if (val.isSelected) {
          acc.push(val.id)
        }
        return acc
      }, [])
    },
    permissionSelectedOptions() {
      const found = this.roles.find((role) => role.id === this.selectedRole)
      return found? found.permissions: this.permissions
    },
  },
  watch: {
    assistantDetail(val) {
      if (val) {
        this.setData()
      }
    },
    permissions(val) {
      if (val) {
        this.setPermissionData()
      }
    },
    assistantPermissions(val) {
      if (val) {
        this.setPermissionData()
      }
    },
    permissionSelectedOptions(val) {
      if (val) {
        this.setPermissionBySelectedRole()
      }
    },
  },
  async created() {
    await this.fetchRoles({
      limit: '',
      page: '',
      search: '',
      masterId: '',
      agentId: '',
    })
    this.setData()
  },
  methods: {
    ...mapActions(['fetchRoles', 'createAssistant', 'updateAssistant']),
    setData() {
      if (this.assistantId && this.assistantDetail) {
        const data = JSON.parse(JSON.stringify(this.assistantDetail))
        this.assistantData = { ...data }
      }
      this.setPermissionData()
    },
    setPermissionData() {
      this.permissionsData = []
      if (this.permissions.length > 0) {
        if(this.assistantPermissions.length > 0){
          this.permissions.forEach((prop) => {
          const found = this.assistantPermissions.find(
            (permission) => permission.key === prop.key
          )
          this.permissionsData.push({
              ...prop,
              isSelected: !!found ,
            })
          })
        } else {
          this.permissions.forEach((prop) => {
            this.permissionsData.push({
              ...prop,
              isSelected: true,
            })
          })
        }
      }
    },
    setPermissionBySelectedRole() {
      this.permissionsData.forEach((prop, key) => {
        const found = this.permissionSelectedOptions.find(
          (permission) => permission.key === prop.key
        )
        this.permissionsData[key].isSelected = !!found
      })
    },
    onSubmit() {
      this.$refs.assistantForm.validate().then( async (success) => {
        if (success) {
          this.isFailed =false
          this.assistantData.permissionIds = this.permissionIds
          if (this.assistantData.id) {
            this.updateAssistant({ id: this.assistantData.id, data: this.assistantData })
          } else {
            this.createAssistant(this.assistantData)
          }
        } else {
          this.isFailed = true
        }
      })
      
    },
  },
}
</script>
